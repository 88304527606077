<template>
  <StoreMoveOut :shopType="2" />
</template>

<script>
import StoreMoveOut from '@/views/store/bill/moveIn/components/index'
export default {
  name: 'joinClientMoveOut',
  components: { StoreMoveOut }
}
</script>
