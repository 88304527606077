<template>
  <SaleGrossProfit :shopType='2' />
</template>

<script>
import SaleGrossProfit from '@/views/decision/saleQueryReport/shopComposite/components'
export default {
  name: 'reportSaleGrossProfit',
  components: { SaleGrossProfit }
}
</script>
