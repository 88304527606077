<template>
  <ShopMoveOut :shopType="2" />
</template>

<script>
import ShopMoveOut from '@/views/shop/bill/moveOut/components/index'
export default {
  name: 'joinClientShopMoveOut',
  components: { ShopMoveOut }
}
</script>
