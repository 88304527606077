<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  franchiseMoveQueryList,
  franchiseMoveQueryListSummary,
  franchiseDetailMoveQueryList,
  franchiseDetailMoveQueryListSummary
} from '@/api/joinClient/report/moveQuery' //配送查询
export default {
  name: 'moveQuery',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '配送查询',
        rowKey: 'shopId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '仓库编号/仓库名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'outStoreNos', label: '配送仓库编码' },
              { filter: 'outStoreNames', label: '配送仓库名称' },
              { filter: 'inStoreNos', label: '收货仓库编码' },
              { filter: 'inStoreNames', label: '收货仓库名称' },
            ]
          },
          this.$select({
            key: 'listCategory',
            option: {
              filter: 'categoryIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'category' || this.options?.tabColumnType == 'goods' || this.options?.tabColumnType == 'detail',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品类别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'category' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listGoods',
            option: {
              filter: 'goodsIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'goods' || this.options?.tabColumnType == 'detail',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listStore',
            option: {
              filter: 'outStoreIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType != 'allocationRefund',
              label: '配送仓库',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择仓库',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({
                        key: 'store',
                        option: {
                          table: {
                            ...this.$dialog({ key: "store" }).table,
                            pagination: {
                              ...this.$dialog({ key: "store" }).table.pagination,
                              // storeTypes: [2]
                            }
                          },
                        },
                      })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: "listFranchiseStore",
            option: {
              seniorSearch: true,
              filter: 'inStoreIds',
              hide: () => this.options?.tabColumnType != 'allocationRefund',
              label: '收货仓库',
              option: { multiple: true, },
            },
          }),
          this.$select({
            key: "listFranchiseStore",
            option: {
              seniorSearch: true,
              filter: 'outStoreIds',
              hide: () => this.options?.tabColumnType == 'allocationRefund',
              label: '配退仓库',
              option: { multiple: true, },
            },
          }),
          this.$select({
            key: 'listStore',
            option: {
              filter: 'inStoreIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'allocationRefund',
              label: '收货仓库',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择仓库',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({
                        key: 'store',
                        option: {
                          table: {
                            ...this.$dialog({ key: "store" }).table,
                            pagination: {
                              ...this.$dialog({ key: "store" }).table.pagination,
                              // storeTypes: [2]
                            }
                          },
                        },
                      })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'distribution',
        tabsColumns: [
          {
            title: '配送汇总',
            type: 'distribution',
            getSummaryApi: franchiseMoveQueryListSummary,
            getListApi: franchiseMoveQueryList,
            defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/delivery/report/export',
              exportName: '配送汇总'
            },
            columns: [
              {
                prop: 'outStoreNo',
                label: '配送仓库编码',
                minWidth: 150
              },
              {
                prop: 'outStoreName',
                label: '配送仓库',
                minWidth: 150
              },
              {
                prop: 'inStoreNo',
                label: '收货仓库编码',
                minWidth: 150
              },
              {
                prop: 'inStoreName',
                label: '收货仓库',
                minWidth: 150
              },
              {
                prop: 'shipmentAmount',
                label: '发货金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'receiptAmount',
                label: '收货金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'differenceAmount',
                label: '差异金额',
                minWidth: 150,
                sortable: true
              },
            ],
            summary: ['shipmentAmount', 'receiptAmount', 'differenceAmount']
          },
          {
            title: '配退汇总',
            type: 'allocationRefund',
            getSummaryApi: franchiseMoveQueryListSummary,
            getListApi: franchiseMoveQueryList,
            defaultBody: { rankingType: 2 },
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/delivery/report/export',
              exportName: '配退汇总'
            },
            columns: [
              {
                prop: 'outStoreNo',
                label: '配退仓库编码',
                minWidth: 150
              },
              {
                prop: 'outStoreName',
                label: '配退仓库',
                minWidth: 150
              },
              {
                prop: 'inStoreNo',
                label: '收货仓库编码',
                minWidth: 150
              },
              {
                prop: 'inStoreName',
                label: '收货仓库',
                minWidth: 150
              },
              {
                prop: 'shipmentAmount',
                label: '配退金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'receiptAmount',
                label: '收货金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'differenceAmount',
                label: '差异金额',
                minWidth: 150,
                sortable: true
              },
            ],
            summary: ['shipmentAmount', 'receiptAmount', 'differenceAmount']
          },
          {
            title: '类别汇总',
            type: 'category',
            getSummaryApi: franchiseMoveQueryListSummary,
            getListApi: franchiseMoveQueryList,
            defaultBody: { rankingType: 3 },
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/delivery/report/export',
              exportName: '类别汇总'
            },
            columns: [
              {
                prop: 'outStoreNo',
                label: '配送仓库编码',
                minWidth: 150
              },
              {
                prop: 'outStoreName',
                label: '配送仓库',
                minWidth: 150
              },
              {
                prop: 'inStoreNo',
                label: '收货仓库编码',
                minWidth: 150
              },
              {
                prop: 'inStoreName',
                label: '收货仓库',
                minWidth: 150
              },
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'shipmentAmount',
                label: '发货金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'receiptAmount',
                label: '收货金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'differenceAmount',
                label: '差异金额',
                minWidth: 150,
                sortable: true
              },
            ],
            summary: ['shipmentAmount', 'receiptAmount', 'differenceAmount']
          },
          {
            title: '商品汇总',
            type: 'goods',
            getSummaryApi: franchiseMoveQueryListSummary,
            getListApi: franchiseMoveQueryList,
            defaultBody: { rankingType: 4 },
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/delivery/report/export',
              exportName: '商品汇总'
            },
            columns: [
              {
                prop: 'outStoreNo',
                label: '配送仓库编码',
                minWidth: 150
              },
              {
                prop: 'outStoreName',
                label: '配送仓库',
                minWidth: 150
              },
              {
                prop: 'inStoreNo',
                label: '收货仓库编码',
                minWidth: 150
              },
              {
                prop: 'inStoreName',
                label: '收货仓库',
                minWidth: 150
              },
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 120
              },
              {
                prop: 'shipmentQty',
                label: '发货数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'shipmentAmount',
                label: '发货金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'differenceQty',
                label: '差异数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'differenceAmount',
                label: '差异金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'receiptQty',
                label: '收货数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'receiptAmount',
                label: '收货金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['shipmentQty', 'shipmentAmount', 'differenceQty', 'differenceAmount', 'receiptQty', 'receiptAmount']
          },
          {
            title: '配送明细',
            type: 'detail',
            getSummaryApi: franchiseDetailMoveQueryListSummary,
            getListApi: franchiseDetailMoveQueryList,
            defaultBody: {},
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/delivery/report/exportDetail',
              exportName: '配送明细'
            },
            columns: [
              {
                prop: 'billNo',
                label: '单号',
                minWidth: 160
              },
              {
                prop: 'billTypeName',
                label: '单据类型',
                minWidth: 120
              },
              {
                prop: 'outStoreNo',
                label: '配送仓库编码',
                minWidth: 150
              },
              {
                prop: 'outStoreName',
                label: '配送仓库',
                minWidth: 150
              },
              {
                prop: 'inStoreNo',
                label: '收货仓库编码',
                minWidth: 150
              },
              {
                prop: 'inStoreName',
                label: '收货仓库',
                minWidth: 150
              },
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 120
              },
              {
                prop: 'unitQty',
                label: '数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'unitPrice',
                label: '单价',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney']
          },
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'update':
          break
        default:
          break
      }
    }
  }
}
</script>
