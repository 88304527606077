<template>
  <div>
    <TablePage ref="tablePage" class="tablepage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>

<script>
import {
  listFranchiseShopInfo,
} from "@/api/joinClient/base/shop";//加盟门店
import {
  franchiseGoodsList,
  saveOrUpdate
} from "@/api/joinClient/base/goods";//加盟商品
import TablePage from '@/components/tablePage'
export default {
  name: 'produceDeptStore',
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        tree: {
          title: '加盟门店',
          getListApi: listFranchiseShopInfo,
          label: 'shopName',
          value: 'shopId',
          defaultExpandAll: true,
          expandOnClickNode: false,
          dataKey: 'rows',
        },
        getListApi: franchiseGoodsList,
        body: {},
        defaultBody: {},
        title: '加盟店商品资料',
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'barcode',
        //搜索
        search: [
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编码' },
              { filter: 'goodsNames', label: '商品名称' },
              { filter: 'barcodes', label: '商品条码' }
            ]
          },
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
            isRestore: true
          }
        ],
        //按钮
        buttons: [
          {
            click: "enterArena",
            label: "进场",
            alertText: "确认要进场选中商品吗？",
            btnType: "dropdown",
            type: "primary",
            other: [
              {
                click: "noEnterArena",
                label: "下场",
                alertText: "确认要下场选中商品吗？",
              },
            ],
          },
          {
            click: "onShelf",
            label: "上架",
            alertText: "确认要上架选中商品吗？",
            btnType: "dropdown",
            type: "primary",
            other: [
              {
                click: "noOnShelf",
                label: "下架",
                alertText: "确认要下架选中商品吗？",
              },
            ],
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'goodsNo',
            label: '商品编码',
            minWidth: 160,
            align: 'center',
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'barcode',
            label: '商品条码',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'unitName',
            label: '单位',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'isCanUse2',
            label: '是否进场',
            minWidth: 120,
            align: 'center',
            formatter: (v, row) => row?.shopGoodsReps[0]?.isCanUse ? '已进场' : '未进场',
          },
          {
            prop: 'isPosVisible2',
            label: '是否上架',
            minWidth: 120,
            align: 'center',
            formatter: (v, row) => row?.shopGoodsReps[0]?.isPosVisible ? '已上架' : '未上架',
          },
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      let objS = []
      let res = {}
      switch (type) {
        case 'enterArena':
          objS = this.options.check.map(item => {
            let isBol = item.shopGoodsReps.some(x => !x.isCanUse)
            if (isBol) {
              item.isCanUse = true
              item.shopGoodsReps[0].isCanUse = true
              return item
            }
          }
          ).filter(item => item != undefined)
          if (objS.length == 0) return this.$message.error('请选择未进场的商品！')
          res = await saveOrUpdate(objS)
          await this.$refs.tablePage.getList();
          this.$message.success('进场成功！')
          break
        case 'noEnterArena':
          objS = this.options.check.map(item => {
            let isBol = item.shopGoodsReps.some(x => x.isCanUse)
            if (isBol) {
              item.isCanUse = false
              item.shopGoodsReps[0].isCanUse = false
              return item
            }
          }
          ).filter(item => item != undefined)
          if (objS.length == 0) return this.$message.error('请选择进场的商品！')
          res = await saveOrUpdate(objS)
          await this.$refs.tablePage.getList();
          this.$message.success('下场成功！')
          break
        case 'onShelf':
          objS = this.options.check.map(item => {
            let isBol = item.shopGoodsReps.some(x => x.isCanUse && !x.isPosVisible)
            if (isBol) {
              item.isPosVisible = true
              item.shopGoodsReps[0].isPosVisible = true
              return item
            }
          }
          ).filter(item => item != undefined)
          if (objS.length == 0) return this.$message.error('请选择已进场未上架的商品！')
          res = await saveOrUpdate(objS)
          await this.$refs.tablePage.getList();
          this.$message.success('上架成功！')
          break
        case 'noOnShelf':
          objS = this.options.check.map(item => {
            let isBol = item.shopGoodsReps.some(x => x.isPosVisible)
            if (isBol) {
              item.isPosVisible = false
              item.shopGoodsReps[0].isPosVisible = false
              return item
            }
          }
          ).filter(item => item != undefined)
          if (objS.length == 0) return this.$message.error('请选择上架的商品！')
          res = await saveOrUpdate(objS)
          await this.$refs.tablePage.getList();
          this.$message.success('下架成功！')
          break
        default:
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped></style>
