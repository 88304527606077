<template>
  <StoreMoveDiff :shopType="3" />
</template>

<script>
import StoreMoveDiff from '@/views/store/bill/moveDiff/components/index'
export default {
  name: 'joinClientMoveDiff',
  components: { StoreMoveDiff }
}
</script>
