<template>
  <!-- 新登录页 -->
  <div class="login-container">
    <div class="bg-logo">
      <!-- <div class="logo">
              <img src="@/assets/imgs/loginimgs/sunyun-logo2.png" alt="加载失败" />
            </div> -->
    </div>
    <div class="login-box">
      <div class="content">
        <div class="loginandreglogo_div">
          <div class="loginandreglogo">
            <img src="@/assets/imgs/loginimgs/sunyun-logo18.svg" alt="加载失败" />
          </div>
        </div>
        <!-- 登录 -->
        <div class="login-area" v-show="curPage === 'login'">
          <div class="title">登录账号</div>
          <!-- 登录 -->
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="账号登录" name="账号登录">
              <!-- 账号登录 -->
              <div class="login-form login_username">
                <el-form
                  :model="nameLoginForm"
                  ref="nameLoginForm"
                  :rules="nameLoginRules"
                >
                  <el-form-item prop="username">
                    <el-input
                      id="account"
                      placeholder="请输入登录账号"
                      v-model="nameLoginForm.username"
                      auto-complete="off"
                    >
                      <template slot="prefix">
                        <img src="@/assets/imgs/loginimgs/username-icon.png" alt="" />
                        <div class="line"></div>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input
                      class="password"
                      placeholder="请输入登录密码"
                      v-model="nameLoginForm.password"
                      @input="nameLoginForm.password = nameLoginForm.password.trim()"
                      auto-complete="off"
                      maxlength="18"
                      :type="passwordType"
                    >
                      <template slot="prefix">
                        <img
                          src="@/assets/imgs/loginimgs/password-icon.png"
                          alt="加载失败"
                        />
                        <div class="line"></div>
                      </template>
                      <template slot="suffix">
                        <img
                          class="pointer"
                          @click="visiblePassword"
                          :src="isShowPasswordIcon"
                          alt="加载失败"
                        />
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <!-- 管理密码 -->

                    <div class="PWmanagement x-bc">
                      <el-checkbox v-model="nameLoginForm.rememberMe" class="rememberPW"
                        >记住密码
                      </el-checkbox>
                      <div class="forgetPwBtn-registerBtn x-bc">
                        <div class="forgetPwBtn pointer" @click="forgetPwBtn">
                          忘记密码
                        </div>
                        <div class="line">|</div>
                        <div class="registerOnTrialBtn pointer" @click="clickRegister">
                          注册试用
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      ref="loginButton"
                      :loading="loading"
                      type="primary"
                      @click="handleLogin('nameLoginForm')"
                      class="loginBtn"
                    >
                      <span v-if="!loading">登 录</span>
                      <span v-else>登 录 中...</span>
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
            <el-tab-pane label="验证码登录" name="验证码登录">
              <!-- 验证码登录 -->
              <div class="login-form login_userphone">
                <el-form
                  :model="phoneLoginForm"
                  ref="phoneLoginForm"
                  :rules="phoneLoginRules"
                >
                  <el-form-item prop="telephone">
                    <el-input
                      id="account"
                      placeholder="请输入手机号"
                      v-model="phoneLoginForm.telephone"
                    >
                      <template slot="prefix">
                        <img src="@/assets/imgs/loginimgs/photo-icon.png" alt="" />
                        <div class="line"></div>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="code">
                    <div class="x-f">
                      <el-input
                        class="password"
                        placeholder="请输入验证码"
                        v-model="phoneLoginForm.code"
                      >
                        <template slot="prefix">
                          <img src="@/assets/imgs/loginimgs/code-icon.png" alt="" />
                          <div class="line"></div>
                        </template>
                      </el-input>
                      <el-button
                        type="primary"
                        class="codeBtn"
                        @click="getMessage('phoneLoginForm')"
                        :disabled="isDisabled"
                        >{{ messageInfo }}
                      </el-button>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <!-- 注册试用 -->

                    <div class="registerOnTrialBtn">
                      <span class="pointer" @click="clickRegister">注册试用</span>
                    </div>
                  </el-form-item>
                  <el-button
                    ref="loginButton"
                    :loading="loading"
                    type="primary"
                    @click="handleLogin('phoneLoginForm')"
                    class="loginBtn"
                  >
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                  </el-button>
                </el-form>
              </div>
            </el-tab-pane>
            <el-tab-pane label="扫码登录" name="扫码登录">
              <div class="login-qrcode">
                <div class="QRcode">
                  <img src="@/assets/imgs/loginimgs/QRcode.png" alt="" />
                </div>
                <div class="tag-registerOnTrialBtn">
                  <div class="tag">打开隼云科技APP扫码登录</div>
                  <div class="line">|</div>
                  <div class="registerOnTrialBtn pointer" @click="clickRegister">
                    注册试用
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="userAgreement">
            登录即表示您已阅读并同意《用户协议》和《客户隐私》
          </div>
          <!-- 更多登录方式 -->
          <div class="other-login">
            <div class="desc">———— 更多登录方式 ————</div>
            <div class="icon-other-logins">
              <div class="other-logins pointer" @click="authorizationBinding">
                <img src="@/assets/imgs/loginimgs/weixin-icon.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 注册 -->
        <div class="register-area" v-show="curPage == 'register'">
          <div class="title">注册账号</div>
          <div class="register-form">
            <el-form :model="registerForm" ref="registerForm" :rules="registerRules">
              <el-form-item prop="telephone">
                <el-input
                  id="account"
                  placeholder="请输入手机号"
                  v-model="registerForm.telephone"
                >
                  <template slot="prefix">
                    <img src="@/assets/imgs/loginimgs/photo-icon.png" alt="" />
                    <div class="line"></div>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="QRcode">
                <div class="x-f">
                  <el-input
                    class="password"
                    placeholder="请输入验证码"
                    v-model="registerForm.QRcode"
                  >
                    <template slot="prefix">
                      <img src="@/assets/imgs/loginimgs/code-icon.png" alt="" />
                      <div class="line"></div>
                    </template>
                  </el-input>
                  <el-button
                    type="primary"
                    class="codeBtn"
                    @click="getMessage('registerForm')"
                    :disabled="isDisabled"
                    >{{ messageInfo }}
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  class="password"
                  placeholder="请输入6-18位密码"
                  v-model="registerForm.password"
                  @input="registerForm.password = registerForm.password.trim()"
                  :type="passwordType"
                  autocomplete="new-password"
                  maxlength="18"
                >
                  <template slot="prefix">
                    <img src="@/assets/imgs/loginimgs/password-icon.png" alt="" />
                    <div class="line"></div>
                  </template>
                  <template slot="suffix">
                    <img
                      class="pointer"
                      @click="visiblePassword"
                      :src="isShowPasswordIcon"
                      alt="加载失败"
                    />
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item prop="agreement">
                <el-checkbox v-model="registerForm.agreement"
                  >您已阅读并同意《用户协议》和《客户隐私》
                </el-checkbox>
              </el-form-item>
            </el-form>
            <el-button
              class="registerBtn"
              :loading="loading"
              type="primary"
              @click="handleReg"
            >
              <span v-if="!loading">注 册</span>
              <span v-else>注 册 中...</span>
            </el-button>
            <div class="skipLogin">
              已有账号?<span class="skipLoginBtn pointer" @click="curPage = 'login'"
                >登录</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { register, unlockLoginUser, environment } from "@/api/login";
import { setToken } from "@/utils/auth";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import { sendCode, smsVerifyCode } from "@/api/system/user"; //短信接口
export default {
  name: "Login",
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    let checkQRcode = (rule, value, callback) => {
      let reg = /^[0-9]\d{5}$/;
      if (!reg.test(value)) {
        callback(new Error("验证码不正确"));
      } else {
        callback();
      }
    };
    return {
      currentEnvironment: "",
      // 按钮缓存动画
      loading: false,
      //登录密码是否显示隐藏
      passwordType: "password",
      //密码是否显示的图标
      isShowPasswordIcon: require("@/assets/imgs/loginimgs/hidePW-icon.png"),
      activeName: "账号登录",
      //登录表单(账号登陆)
      nameLoginForm: {
        type: "username", //username区分账号登陆/userphone手机号验证码登陆
        username: "",
        password: "",
        rememberMe: false,
      },
      //登录表单(手机号登陆)
      phoneLoginForm: {
        type: "userphone", //username区分账号登陆/userphone手机号验证码登陆
        telephone: "",
        code: "",
      },
      //注册
      registerForm: {
        telephone: "",
        password: "",
        QRcode: "",
        agreement: undefined,
      },
      // 发送短信验证码按钮文本
      messageInfo: "获取验证码",
      isDisabled: false, // 是否可点击
      //当前页面
      curPage: "login",
      // 登录跳转的路径
      redirect: undefined,
      //登录校验规则
      nameLoginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的登录账号" },
          {
            min: 2,
            max: 60,
            message: "长度在 2 到 60 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入6到18位登录密码" },
          {
            min: 6,
            max: 18,
            message: "请输入6到18位登录密码",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: "密码不能包含中文",
            trigger: ["blur", "change"],
          },
        ],
      },
      phoneLoginRules: {
        telephone: [
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: "number",
            validator: checkQRcode,
            trigger: "blur",
          },
        ],
      },
      //登录校验规则
      registerRules: {
        telephone: [
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: ["blur", "change"],
          },
        ],
        QRcode: [
          {
            required: "number",
            validator: checkQRcode,
            trigger: "blur",
          },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入6到18位登录密码" },
          {
            min: 6,
            max: 18,
            message: "请输入6到18位登录密码",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: "密码不能包含中文",
            trigger: ["blur", "change"],
          },
        ],
        agreement: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请阅读同意协议",
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  // computed:{
  //     ...Vuex.mapState([''])
  // }
  created() {
    this.getEnvironment();
    const username = this.getUrlCode("username");
    const password = this.getUrlCode("password");
    const tenantId = this.getUrlCode("tenantId");
    if (username && password && tenantId) {
      // 有参数 需要直接登录
      this.nameLoginForm.username = username;
      this.nameLoginForm.password = password;
      this.nameLoginForm.tenantId = tenantId;
      this.nameLoginForm.loginFrom = -1;
      this.$nextTick(() => {
        this.handleLogin("nameLoginForm");
      });
    }
    // //门店调拨单(收银端跳转过来)
    // const moveOrderInfo = {
    //   billId: this.getUrlCode("billId"),
    //   inStoreId: this.getUrlCode("inStoreId"),
    //   outStoreId: this.getUrlCode("outStoreId"),
    // };
    // if (password && username) {
    //   // 有参数 需要直接登录
    //   this.nameLoginForm.username = username;
    //   this.nameLoginForm.password = password;
    //   this.$nextTick(() => {
    //     if (moveOrderInfo.billId) {
    //       this.handleLogin("nameLoginForm", moveOrderInfo);
    //     } else {
    //       this.handleLogin("nameLoginForm");
    //     }
    //   });
    // }
    //获取记住密码的账号
    this.getCookie();
  },
  methods: {
    //点击注册按钮
    clickRegister() {
      this.curPage = "register";
      this.visiblePassword();
    },
    //切割
    getUrlCode(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            decodeURIComponent(window.location.href)
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //其他登录方式授权绑定
    authorizationBinding() {
      this.$router.push("/authorizationBinding");
    },
    //找回密码
    forgetPwBtn() {
      this.$router.push("/retrievePW");
    },
    //是否显示密码
    visiblePassword() {
      this.passwordType = this.passwordType === "text" ? "password" : "text";
      this.isShowPasswordIcon =
        this.isShowPasswordIcon === require("@/assets/imgs/loginimgs/hidePW-icon.png")
          ? require("@/assets/imgs/loginimgs/showPW-icon.png")
          : require("@/assets/imgs/loginimgs/hidePW-icon.png");
    },
    // 获取存储的账号密码
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.nameLoginForm = {
        ...this.nameLoginForm,
        type: "username", //username区分账号登陆/userphone手机号验证码登陆
        username: username === undefined ? this.nameLoginForm.username : username,
        password:
          password === undefined ? this.nameLoginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    // 发送短信验证码
    async getMessage(formName) {
      if (this.currentEnvironment != "prod") return;
      console.log("进来没有", formName);
      console.log("进来没有999", this.$refs[formName]);
      this.$refs[formName].validateField("telephone", async (valid) => {
        if (!valid) {
          console.log("进来没有123", this[formName]);
          try {
            let time = 60;
            this.messageInfo = time + "秒后重试";
            this.isDisabled = true;
            await sendCode(this[formName].telephone);
            time = 59;
            let timer = setInterval(() => {
              if (time == 0) {
                clearInterval(timer);
                this.messageInfo = "获取验证码";
                this.isDisabled = false;
              } else {
                this.messageInfo = time + "秒后重试";
                this.isDisabled = true;
                time--;
              }
            }, 1000);
          } catch {}
        }
      });
    },
    // 验证验证码是否正确
    // verifyCode() {
    //   return new Promise(async (resolve, reject) => {
    //     if (this.registerForm.QRcode.length == 6) {
    //       await smsVerifyCode({
    //         telephone: this.registerForm.telephone,
    //         code: this.registerForm.QRcode,
    //       })
    //         .then((res) => {
    //           resolve(res.status == "success" ? true : false);
    //         })
    //         .catch((err) => {
    //           this.$message.error(err.msg);
    //           resolve(false);
    //         });
    //     }
    //   });
    // },
    // 验证验证码是否正确
    verifyCode() {
      return new Promise(async (resolve, reject) => {
        // 删除或注释掉原有的验证码校验代码
        if (this.registerForm.QRcode.length == 6) {
          await smsVerifyCode({
            telephone: this.registerForm.telephone,
            code: this.registerForm.QRcode,
          })
            .then((res) => {
              resolve(res.status == "success" ? true : false);
            })
            .catch((err) => {
              this.$message.error(err.msg);
              resolve(false);
            });
        }

        // 直接将校验结果设置为 true，允许任意验证码登录
        // resolve(true)
      });
    },

    //注册
    async handleReg() {
      try {
        await this.$refs.registerForm.validate();
        const isCode = await this.verifyCode(this.registerForm.QRcode);
        if (!isCode) return;
        this.loading = true;
        try {
          await register(this.registerForm);
          this.$message.success("注册成功!");
          this.curPage = "login";
          this.visiblePassword();
          this.nameLoginForm.username = this.registerForm.telephone;
          this.nameLoginForm.password = this.registerForm.password;
          //注册
          this.registerForm = {
            telephone: "",
            password: "",
            QRcode: "",
            agreement: undefined,
          };
        } catch (error) {
          console.log(error);
        }
      } catch (error) {}
      this.loading = false;
    },
    //登录事件
    handleLogin(formName) {
      //moveOrder是否调拨单(从收银端调拨进入)
      //这里报错
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.nameLoginForm.rememberMe) {
            Cookies.set("username", this.nameLoginForm.username, {
              expires: 30,
            });
            Cookies.set("password", encrypt(this.nameLoginForm.password), {
              expires: 30,
            });
            Cookies.set("rememberMe", this.nameLoginForm.rememberMe, {
              expires: 30,
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }
          this.$store
            .dispatch("Login", this[formName])
            .then((res) => {
              this.$SOCKET.initWebSocket();
              this.$router.push({ path: this.redirect || "/" }).catch(() => {});
              if (res?.availableUser == false) {
                this.$message.error(res.msg);
              } else {
                this.$message.success("登录成功");
              }
            })
            .catch((err) => {
              this.loading = false;
              //账号已被登录
              if (err.unlockLoginKey) {
                this.$confirm("该账号已被登录, 是否解锁并继续登录?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(async () => {
                    setToken(err.token);
                    await unlockLoginUser(err.unlockLoginKey);
                    this.handleLogin(formName);
                  })
                  .catch(() => {});
              }
            });
        }
      });
    },
    // 获取当前环境
    getEnvironment() {
      environment().then((res) => {
        this.currentEnvironment = res.data;
        if (this.currentEnvironment != "prod") {
          this.isDisabled = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  height: 100%;

  .bg-logo {
    flex-grow: 1;
    background: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-13/d4f071eec4a54f858f24c93cc6a5334e.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 100%;

    .logo {
      width: 150px;
      height: 42px;
      position: absolute;
      top: 4%;
      left: 5%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .login-box {
    width: 480px;

    .content {
      width: 336px;
      min-height: 666px;
      margin: 0 auto;

      .loginandreglogo_div {
        display: flex;
        justify-content: center;
        align-items: center;

        .loginandreglogo {
          width: 150px;
          height: 42px;
          margin: 50px 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .login-area,
      .register-area {
        .title {
          text-align: center;
          font-size: 18px;
          color: #343e4c;
          font-weight: 700;
        }

        // tab
        ::v-deep .el-tabs__header {
          margin: 15px 0 30px;
        }

        ::v-deep .el-tabs__item.is-active {
          color: #409eff;
        }

        ::v-deep .el-tabs__item {
          height: 32px;
          line-height: 32px;
        }

        // 输入框图标
        ::v-deep .el-input__prefix {
          border: none;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;

          img {
            width: 20px;
            height: 20px;
          }

          .line {
            width: 1px;
            height: 20px;
            background-color: #e2e4e9;
            margin: 0 8px;
          }
        }

        ::v-deep .el-input__inner {
          padding-left: 48px;
        }

        ::v-deep .el-input__suffix {
          width: 30px;
        }

        //验证码按钮
        .codeBtn {
          margin-left: 5px;
        }

        //扫码登陆
        .login-qrcode {
          text-align: center;
          font-size: 14px;

          .QRcode {
            width: 125px;
            height: 125px;
            margin: 0 auto 20px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .tag-registerOnTrialBtn {
            display: flex;
            justify-content: center;

            .line {
              color: #999;
              margin: 0 10px;
            }
          }
        }

        //管理密码
        .PWmanagement {
          color: #999;
          font-size: 14px;

          .forgetPwBtn-registerBtn {
            width: 135px;
          }

          ::v-deep .el-checkbox__label {
            color: #999;
          }
        }

        //注册试用按钮
        .registerOnTrialBtn {
          text-align: right;
          color: #409eff;
        }

        //登陆按钮/注册按钮
        .loginBtn,
        .registerBtn {
          width: 100%;
          margin-bottom: 15px;
        }

        //用户协议
        .userAgreement {
          font-size: 12px;
          padding: 10px 0;
          text-align: center;
        }

        //更多登录方式
        .other-login {
          text-align: center;
          font-size: 14px;
          color: #999;
          margin-top: 20px;

          .icon-other-logins {
            height: 33px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 15px 0;

            .other-logins {
              width: 32px;
              height: 32px;
              margin: 0 9px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        //注册表单
        .register-form {
          margin-top: 50px;
          //跳转登录
          .skipLogin {
            font-size: 14px;
            text-align: center;
            color: #606266;

            .skipLoginBtn {
              color: #409eff;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}
</style>
