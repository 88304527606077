<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  franchiseShopSaleList,
  franchiseShopSaleDetailList,
  franchiseListSummary,
  franchiseListDetailSummary
} from '@/api/joinClient/report/moveGrossProfit' //门店销售排行
export default {
  name: 'saleRankReportShops',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '加盟商毛利',
        rowKey: 'shopId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'shopNos', label: '门店编码' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          this.$select({
            key: "listFranchiseStore",
            option: {
              seniorSearch: true,
              filter: 'inStoreIds',
              option: { multiple: true, },
            },
          }),
          this.$select({
            key: 'listGoods',
            option: {
              filter: 'goodsIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType != 'salesVolume',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'salesVolume',
        tabsColumns: [
          {
            title: '加盟店毛利',
            type: 'salesVolume',
            getSummaryApi: franchiseListSummary,
            getListApi: franchiseShopSaleList,
            defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/shop/strategy/shopSale/ranking/export',
              exportName: '加盟店毛利'
            },
            columns: [
              {
                prop: 'outStoreNo',
                label: '发货仓库编码',
                minWidth: 150
              },
              {
                prop: 'outStoreName',
                label: '发货仓库',
                minWidth: 180
              },
              {
                prop: 'inStoreNo',
                label: '收货仓库编码',
                minWidth: 150
              },
              {
                prop: 'inStoreName',
                label: '收货仓库',
                minWidth: 180
              },
              {
                prop: 'billNo',
                label: '单号',
                minWidth: 150
              },
              {
                prop: 'billQty',
                label: '配送数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'billMoney',
                label: '配送金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitPurMoney',
                label: '成本金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'lineGainRate',
                label: '毛利率',
                minWidth: 100,
                sortable: true
              },
              {
                prop: 'unitSaleMoney',
                label: '参考零售金额',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['billQty', 'billMoney', 'unitPurMoney', 'lineGain', 'lineGainRate', 'unitSaleMoney']
          },
          {
            title: '加盟店商品毛利',
            type: 'salesMoney',
            getSummaryApi: franchiseListDetailSummary,
            getListApi: franchiseShopSaleDetailList,
            defaultBody: {},
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/shop/strategy/shopSale/ranking/listDetailExport',
              exportName: '加盟店商品毛利'
            },
            columns: [
              {
                prop: 'outStoreNo',
                label: '发货仓库编码',
                minWidth: 150
              },
              {
                prop: 'outStoreName',
                label: '发货仓库',
                minWidth: 180
              },
              {
                prop: 'inStoreNo',
                label: '收货仓库编码',
                minWidth: 150
              },
              {
                prop: 'inStoreName',
                label: '收货仓库',
                minWidth: 180
              },
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 120
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 120
              },
              {
                prop: 'unitQty',
                label: '调出数量',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'unitMoney',
                label: '调出金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'unitCostPrice',
                label: '调出成本',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'lineGain',
                label: '毛利',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'partnerName',
                label: '供应商',
                minWidth: 150,
                sortable: true
              }
            ],
            summary: ['unitQty', 'unitMoney', 'unitCostPrice', 'lineGain']
          },
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'update':
          break
        default:
          break
      }
    }
  }
}
</script>
