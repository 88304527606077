<template>
  <SaleTotal :shopType='2' />
</template>

<script>
import SaleTotal from '@/views/shop/report/saleTotal/components'
export default {
  name: 'JoinClientSaleTotal',
  components: { SaleTotal }
}
</script>
