<template>
  <ShopOrder :shopType='2' />
</template>

<script>
import ShopOrder from '@/views/shop/bill/order/components'
export default {
  name: 'joinClientShopOrder',
  components: { ShopOrder }
}
</script>
