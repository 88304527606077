<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  franchiseVipcardList, franchiseVipcardListSummary, listBalanceFillDetail, listBalanceFillDetailSummary, listFillDetail, listFillDetailSummary, listPayDetail, listPayDetailSummary
} from '@/api/joinClient/report/vipQuery' //加盟会员
export default {
  name: 'vipQuery',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '加盟会员查询',
        rowKey: 'shopId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          // {
          //   type: 'filters',
          //   tip: '门店编号/门店名称',
          //   model: '',
          //   filters: [
          //     { filter: 'query', label: '全部' },
          //     { filter: 'shopNos', label: '门店编码' },
          //     { filter: 'shopNames', label: '门店名称' }
          //   ]
          // },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'vip',
            option: {
              filter: 'vipIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType != 'salesVolume2',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择会员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'vip' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'salesVolume1',
        tabsColumns: [
          {
            title: '加盟会员查询资料',
            type: 'salesVolume1',
            getListApi: franchiseVipcardList,
            getSummaryApi: franchiseVipcardListSummary,
            defaultBody: {},
            exportOption: {
              ossKeyUrl:
                '/api/system/franchise/vip/base/vipcard/getListExport',
              fastExportUrl:
                '/api/system/franchise/vip/base/vipcard/listExport',
              exportName: '加盟会员查询资料'
            },
            columns: [
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 180
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 160
              },
              {
                prop: 'sexName',
                label: '性别',
                minWidth: 100
              },
              {
                prop: 'birthday',
                label: '会员生日',
                minWidth: 110
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 120
              },
              {
                prop: 'address',
                label: '地址',
                minWidth: 180,
              },
              {
                prop: 'remark',
                label: '备注',
                minWidth: 150,
                sortable: true
              },
              {
                prop: 'endValidDate',
                label: '会员有效期',
                minWidth: 155,
              },
              {
                prop: "allScore",
                label: "累计积分",
                minWidth: 160,
                align: "center",
                sortable: true
              },
              {
                prop: "usableScore",
                label: "可用积分",
                minWidth: 160,
                align: "center",
                sortable: true
              },
              {
                prop: 'allConsumeMoney',
                label: '消费金额',
                minWidth: 150,
                sortable: true
              },
              {
                prop: "totalUsableMoney",
                label: "卡内金额",
                minWidth: 120,
                align: "center",
                sortable: true
              },
              {
                prop: 'createBy',
                label: '创建人',
                minWidth: 150,
              },
              {
                prop: 'shopName',
                label: '发卡门店',
                minWidth: 150,
              },
              {
                prop: 'createTime',
                label: '创建时间',
                minWidth: 155,
              }
            ],
            summary: ['allScore', 'usableScore', 'allConsumeMoney', 'totalUsableMoney']
          },
          {
            title: '会员余额汇总表',
            type: 'salesVolume2',
            getListApi: listBalanceFillDetail,
            getSummaryApi: listBalanceFillDetailSummary,
            defaultBody: {},
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/vip/base/vipcard/listBalanceFillDetailExport',
              exportName: '会员余额汇总表'
            },
            columns: [
              {
                prop: 'vipShopNo',
                label: '加盟发卡门店编码',
                minWidth: 150
              },
              {
                prop: 'vipShopName',
                label: '加盟发卡门店',
                minWidth: 150
              },
              {
                prop: 'vipBalance',
                label: '会员余额',
                minWidth: 150
              },
              {
                prop: 'vipNumber',
                label: '会员数量',
                minWidth: 150
              }
            ],
            summary: ['vipBalance', 'vipNumber']
          },
          {
            title: '会员储值明细表',
            type: 'salesVolume3',
            getListApi: listFillDetail,
            getSummaryApi: listFillDetailSummary,
            defaultBody: {},
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/vip/base/vipcard/listFillDetailExport',
              exportName: '会员储值明细表'
            },
            columns: [
              {
                prop: 'shopNo',
                label: '加盟业务发生门店编码',
                minWidth: 160
              },
              {
                prop: 'shopName',
                label: '加盟业务发生门店',
                minWidth: 150
              },
              {
                prop: 'vipShopNo',
                label: '加盟发卡门店编码',
                minWidth: 150
              },
              {
                prop: 'vipShopName',
                label: '加盟发卡门店',
                minWidth: 150
              },
              {
                prop: 'billNo',
                label: '业务单号',
                minWidth: 180
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 180
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'fillTime',
                label: '时间',
                minWidth: 155
              },
              {
                prop: 'fillTypeName',
                label: '业务类型',
                minWidth: 150
              },
              {
                prop: 'payTypes',
                align: 'center',
                label: '收款方式',
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.(x => ({
                    ...x,
                    label: x.payModeName,
                    prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find(y => y.payModeId === x.payModeId)
                        ?.payMoney || 0
                  })) || []
              },
              {
                prop: 'fillMoneyTotal',
                label: '金额',
                minWidth: 150
              },
              {
                prop: 'largessMoney',
                label: '赠送金额',
                minWidth: 150
              },
              {
                prop: 'fillMoney',
                label: '实付金额',
                minWidth: 150
              },
              {
                prop: 'cashierName',
                label: '操作员',
                minWidth: 150
              },
              {
                prop: 'remark',
                label: '备注',
                minWidth: 150
              },
            ],
            summary: ['fillMoneyTotal', 'fillMoney', 'largessMoney']
          },
          {
            title: '会员支付明细查询',
            type: 'salesVolume4',
            exportOption: {
              fastExportUrl:
                '/api/system/franchise/vip/base/vipcard/listPayDetailExport',
              exportName: '会员支付明细'
            },
            getListApi: listPayDetail,
            getSummaryApi: listPayDetailSummary,
            defaultBody: { summaryType: 1 },
            columns: [
              {
                prop: 'payDate',
                label: '日期',
                minWidth: 155
              },
              {
                prop: 'billPosNo',
                label: '小票号',
                minWidth: 180
              },
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'machineNo',
                label: '收银机编号',
                minWidth: 150
              },
              {
                prop: 'machineName',
                label: '收银机名称',
                minWidth: 150
              },
              {
                prop: 'cashierNo',
                label: '收银员编号',
                minWidth: 150
              },
              {
                prop: 'cashierName',
                label: '收银员名称',
                minWidth: 150
              },
              {
                prop: 'vipLevelNo',
                label: '级别编码',
                minWidth: 150
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 180
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'payTypeName',
                label: '付款类型',
                minWidth: 120
              },
              {
                prop: 'payFillMoney',
                label: '扣除可用充值',
                minWidth: 130,
                sortable: true
              },
              {
                prop: 'payLargessMoney',
                label: '扣除可用赠送',
                minWidth: 130,
                sortable: true
              },
              {
                prop: 'payGainMoney',
                label: '扣除可用返现',
                minWidth: 130,
                sortable: true
              },
              {
                prop: 'payMoney',
                label: '付款总额',
                minWidth: 120,
                sortable: true
              },
              {
                prop: 'endMoney',
                label: '付款后卡余额',
                minWidth: 130,
                sortable: true
              }
            ],
            summary: [
              'payMoney',
              'payFillMoney',
              'payLargessMoney',
              'payGainMoney',
              'endMoney'
            ]
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'update':
          break
        default:
          break
      }
    }
  }
}
</script>
