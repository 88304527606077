<template>
  <StoreQuery :shopType='2' />
</template>

<script>
import StoreQuery from '@/views/shop/report/storeQuery/components'
export default {
  name: 'JoinClientStoreQuery',
  components: { StoreQuery }
}
</script>
