<template>
  <MaterialOrder :shopType='2' />
</template>

<script>
import MaterialOrder from '@/views/shop/bill/materialOrder/components/index'
export default {
  name: 'joinClientMaterialOrder',
  components: { MaterialOrder }
}
</script>
