var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "bg-logo" }),
    _c("div", { staticClass: "login-box" }, [
      _c("div", { staticClass: "content" }, [
        _vm._m(0),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.curPage === "login",
                expression: "curPage === 'login'",
              },
            ],
            staticClass: "login-area",
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("登录账号")]),
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "账号登录", name: "账号登录" } },
                  [
                    _c(
                      "div",
                      { staticClass: "login-form login_username" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "nameLoginForm",
                            attrs: {
                              model: _vm.nameLoginForm,
                              rules: _vm.nameLoginRules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "username" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      id: "account",
                                      placeholder: "请输入登录账号",
                                      "auto-complete": "off",
                                    },
                                    model: {
                                      value: _vm.nameLoginForm.username,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.nameLoginForm,
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression: "nameLoginForm.username",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prefix" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/imgs/loginimgs/username-icon.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "line" }),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "password",
                                    attrs: {
                                      placeholder: "请输入登录密码",
                                      "auto-complete": "off",
                                      maxlength: "18",
                                      type: _vm.passwordType,
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.nameLoginForm.password =
                                          _vm.nameLoginForm.password.trim()
                                      },
                                    },
                                    model: {
                                      value: _vm.nameLoginForm.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.nameLoginForm,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "nameLoginForm.password",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prefix" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/imgs/loginimgs/password-icon.png"),
                                          alt: "加载失败",
                                        },
                                      }),
                                      _c("div", { staticClass: "line" }),
                                    ]),
                                    _c("template", { slot: "suffix" }, [
                                      _c("img", {
                                        staticClass: "pointer",
                                        attrs: {
                                          src: _vm.isShowPasswordIcon,
                                          alt: "加载失败",
                                        },
                                        on: { click: _vm.visiblePassword },
                                      }),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("el-form-item", [
                              _c(
                                "div",
                                { staticClass: "PWmanagement x-bc" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "rememberPW",
                                      model: {
                                        value: _vm.nameLoginForm.rememberMe,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.nameLoginForm,
                                            "rememberMe",
                                            $$v
                                          )
                                        },
                                        expression: "nameLoginForm.rememberMe",
                                      },
                                    },
                                    [_vm._v("记住密码 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "forgetPwBtn-registerBtn x-bc",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "forgetPwBtn pointer",
                                          on: { click: _vm.forgetPwBtn },
                                        },
                                        [_vm._v(" 忘记密码 ")]
                                      ),
                                      _c("div", { staticClass: "line" }, [
                                        _vm._v("|"),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "registerOnTrialBtn pointer",
                                          on: { click: _vm.clickRegister },
                                        },
                                        [_vm._v(" 注册试用 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    ref: "loginButton",
                                    staticClass: "loginBtn",
                                    attrs: {
                                      loading: _vm.loading,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleLogin("nameLoginForm")
                                      },
                                    },
                                  },
                                  [
                                    !_vm.loading
                                      ? _c("span", [_vm._v("登 录")])
                                      : _c("span", [_vm._v("登 录 中...")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "验证码登录", name: "验证码登录" } },
                  [
                    _c(
                      "div",
                      { staticClass: "login-form login_userphone" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "phoneLoginForm",
                            attrs: {
                              model: _vm.phoneLoginForm,
                              rules: _vm.phoneLoginRules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "telephone" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      id: "account",
                                      placeholder: "请输入手机号",
                                    },
                                    model: {
                                      value: _vm.phoneLoginForm.telephone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.phoneLoginForm,
                                          "telephone",
                                          $$v
                                        )
                                      },
                                      expression: "phoneLoginForm.telephone",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prefix" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/imgs/loginimgs/photo-icon.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "line" }),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("el-form-item", { attrs: { prop: "code" } }, [
                              _c(
                                "div",
                                { staticClass: "x-f" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "password",
                                      attrs: { placeholder: "请输入验证码" },
                                      model: {
                                        value: _vm.phoneLoginForm.code,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.phoneLoginForm,
                                            "code",
                                            $$v
                                          )
                                        },
                                        expression: "phoneLoginForm.code",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "prefix" }, [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/imgs/loginimgs/code-icon.png"),
                                            alt: "",
                                          },
                                        }),
                                        _c("div", { staticClass: "line" }),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "codeBtn",
                                      attrs: {
                                        type: "primary",
                                        disabled: _vm.isDisabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getMessage(
                                            "phoneLoginForm"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.messageInfo) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-form-item", [
                              _c("div", { staticClass: "registerOnTrialBtn" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "pointer",
                                    on: { click: _vm.clickRegister },
                                  },
                                  [_vm._v("注册试用")]
                                ),
                              ]),
                            ]),
                            _c(
                              "el-button",
                              {
                                ref: "loginButton",
                                staticClass: "loginBtn",
                                attrs: {
                                  loading: _vm.loading,
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleLogin("phoneLoginForm")
                                  },
                                },
                              },
                              [
                                !_vm.loading
                                  ? _c("span", [_vm._v("登 录")])
                                  : _c("span", [_vm._v("登 录 中...")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "扫码登录", name: "扫码登录" } },
                  [
                    _c("div", { staticClass: "login-qrcode" }, [
                      _c("div", { staticClass: "QRcode" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/imgs/loginimgs/QRcode.png"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "tag-registerOnTrialBtn" }, [
                        _c("div", { staticClass: "tag" }, [
                          _vm._v("打开隼云科技APP扫码登录"),
                        ]),
                        _c("div", { staticClass: "line" }, [_vm._v("|")]),
                        _c(
                          "div",
                          {
                            staticClass: "registerOnTrialBtn pointer",
                            on: { click: _vm.clickRegister },
                          },
                          [_vm._v(" 注册试用 ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "userAgreement" }, [
              _vm._v(" 登录即表示您已阅读并同意《用户协议》和《客户隐私》 "),
            ]),
            _c("div", { staticClass: "other-login" }, [
              _c("div", { staticClass: "desc" }, [
                _vm._v("———— 更多登录方式 ————"),
              ]),
              _c("div", { staticClass: "icon-other-logins" }, [
                _c(
                  "div",
                  {
                    staticClass: "other-logins pointer",
                    on: { click: _vm.authorizationBinding },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/imgs/loginimgs/weixin-icon.svg"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.curPage == "register",
                expression: "curPage == 'register'",
              },
            ],
            staticClass: "register-area",
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("注册账号")]),
            _c(
              "div",
              { staticClass: "register-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "registerForm",
                    attrs: {
                      model: _vm.registerForm,
                      rules: _vm.registerRules,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "telephone" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              id: "account",
                              placeholder: "请输入手机号",
                            },
                            model: {
                              value: _vm.registerForm.telephone,
                              callback: function ($$v) {
                                _vm.$set(_vm.registerForm, "telephone", $$v)
                              },
                              expression: "registerForm.telephone",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/loginimgs/photo-icon.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "line" }),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("el-form-item", { attrs: { prop: "QRcode" } }, [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "password",
                              attrs: { placeholder: "请输入验证码" },
                              model: {
                                value: _vm.registerForm.QRcode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.registerForm, "QRcode", $$v)
                                },
                                expression: "registerForm.QRcode",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/imgs/loginimgs/code-icon.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "line" }),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "codeBtn",
                              attrs: {
                                type: "primary",
                                disabled: _vm.isDisabled,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getMessage("registerForm")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.messageInfo) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "password",
                            attrs: {
                              placeholder: "请输入6-18位密码",
                              type: _vm.passwordType,
                              autocomplete: "new-password",
                              maxlength: "18",
                            },
                            on: {
                              input: function ($event) {
                                _vm.registerForm.password =
                                  _vm.registerForm.password.trim()
                              },
                            },
                            model: {
                              value: _vm.registerForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.registerForm, "password", $$v)
                              },
                              expression: "registerForm.password",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/loginimgs/password-icon.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "line" }),
                            ]),
                            _c("template", { slot: "suffix" }, [
                              _c("img", {
                                staticClass: "pointer",
                                attrs: {
                                  src: _vm.isShowPasswordIcon,
                                  alt: "加载失败",
                                },
                                on: { click: _vm.visiblePassword },
                              }),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "agreement" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.registerForm.agreement,
                              callback: function ($$v) {
                                _vm.$set(_vm.registerForm, "agreement", $$v)
                              },
                              expression: "registerForm.agreement",
                            },
                          },
                          [_vm._v("您已阅读并同意《用户协议》和《客户隐私》 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "registerBtn",
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: { click: _vm.handleReg },
                  },
                  [
                    !_vm.loading
                      ? _c("span", [_vm._v("注 册")])
                      : _c("span", [_vm._v("注 册 中...")]),
                  ]
                ),
                _c("div", { staticClass: "skipLogin" }, [
                  _vm._v(" 已有账号?"),
                  _c(
                    "span",
                    {
                      staticClass: "skipLoginBtn pointer",
                      on: {
                        click: function ($event) {
                          _vm.curPage = "login"
                        },
                      },
                    },
                    [_vm._v("登录")]
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loginandreglogo_div" }, [
      _c("div", { staticClass: "loginandreglogo" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/imgs/loginimgs/sunyun-logo18.svg"),
            alt: "加载失败",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }