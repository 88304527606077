<template>
  <!-- 找回密码 -->
  <div class="retrievePW">
    <div class="main">
      <div class="title">找回密码</div>
      <el-form :model="form" ref="form" label-width="90px" :rules="formRules">
        <!-- 进度条 -->
        <el-steps :active="curStep" align-center>
          <el-step title="账号验证"></el-step>
          <el-step title="重置密码"></el-step>
          <el-step title="重新登录"></el-step>
        </el-steps>
        <!-- 内容区域 -->
        <div class="content">
          <!-- 第一步 -->
          <div class="firstStep" v-if="curStep === 1">
            <el-form-item label="您的手机号:" prop="telephone" label-width="100px">
              <el-input
                placeholder="请输入手机号"
                v-model="form.telephone"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-button type="primary" class="nextBtn" @click="nextStep">下一步</el-button>
          </div>
          <!-- 第二步 -->
          <div class="theSecondStep" v-if="curStep === 2">
            <el-form-item label="您的手机号:">
              <div>{{ this.form.telephone }}</div>
            </el-form-item>
            <el-form-item label="验证码:">
              <div class="x-f">
                <el-input
                  placeholder="请输入验证码"
                  v-model="form.code"
                  maxlength="10"
                ></el-input>
                <el-button
                  type="primary"
                  class="codeBtn"
                  :loading="loadingCode"
                  :disabled="loadingCode || countdown > 0"
                  @click="getVerificationCode"
                >
                  {{
                    loadingCode
                      ? "发送中..."
                      : countdown > 0
                      ? `${countdown}秒后重试`
                      : "获取验证码"
                  }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item label="新密码:" prop="newPassword">
              <el-input
                placeholder="请输入6到18位登录密码"
                show-password
                v-model="form.newPassword"
                @input="form.newPassword = form.newPassword.trim()"
                maxlength="18"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码:" prop="confirmPwd">
              <el-input
                placeholder="请确认密码"
                show-password
                v-model="form.confirmPwd"
                @input="form.confirmPwd = form.confirmPwd.trim()"
                maxlength="18"
              ></el-input>
            </el-form-item>
            <el-button type="primary" class="submitBtn" @click="submit">提交</el-button>
          </div>
          <!-- 第三步 -->
          <div class="theThirdStep" v-if="curStep === 3">
            <div class="icon">
              <img src="@/assets/imgs/loginimgs/success-icon.png" alt="加载失败" />
            </div>
            <div class="tag">修改成功,请重新登录!</div>
            <div class="skipLoginBtn pointer" @click="skipLogin">去登录</div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { findByTelephoneAPI, recoverPassword, validateTelephone } from "@/api/login";
import { sendCode, smsVerifyCode } from "@/api/system/user"; //发送手机号、验证手机号是否正确
export default {
  name: "retrievePW",
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致 !"));
      } else {
        callback();
      }
    };
    return {
      countdown: 0, //验证码倒计时
      loadingCode: false, //验证码
      //当前步骤
      curStep: 1,
      form: {},
      telephone: "",
      formRules: {
        telephone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[0-9]{11}$/,
            message: "手机号格式不正确",
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            max: 18,
            message: "请输入6到18位登录密码",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: "密码不能包含中文",
            trigger: ["blur", "change"],
          },
        ],
        confirmPwd: [
          {
            required: true,
            message: "请确认密码",
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            max: 18,
            message: "请输入6到18位登录密码",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: "密码不能包含中文",
            trigger: ["blur", "change"],
          },
          {
            validator: validatePass2,
            trigger: ["blur", "change"],
            required: true,
          },
        ],
      },
    };
  },
  methods: {
    //验证验证码是不是正确
    async submit() {
      try {
        this.loading = true;
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            const res = await smsVerifyCode({
              telephone: this.form.telephone,
              code: this.form.code,
            });
            if (res.status != "success") {
              this.loading = false;
              return this.$message.error("手机号验证失败");
            }
            await recoverPassword({
              code: this.form.code,
              newPassword: this.form.newPassword,
              phone: this.form.telephone,
            });
            this.$message.success("重置密码成功");
            this.curStep = 3;
            this.loading = false;
          }
        });
      } catch (error) {
        this.loading = false;
      }
    },
    //第二步获取验证码
    async getVerificationCode() {
      this.loadingCode = true;
      this.startCountdown();
      await sendCode(this.form.telephone);
      this.loadingCode = false;
    },
    startCountdown() {
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
    //第一步
    nextStep() {
      this.$refs.form.validateField("telephone", async (errorMsg) => {
        if (!errorMsg) {
          const res = await validateTelephone(this.form.telephone);
          if (res.exist) {
            this.curStep = 2;
          } else {
            this.$message.error("手机号不存在");
          }
        }
      });
    },
    async getTel() {
      const res = await findByTelephoneAPI(telephone);
      console.log("res", res);
    },
    //跳转到登录页面
    skipLogin() {
      this.$router.push("./user/login");
    },
    handleInput(value) {
      let inputValue = String(value).slice(0, 11); // 截取前11个字符
      // 使用正则表达式验证手机号格式
      const phonePattern = /^1[3456789]\d{9}$/;

      if (phonePattern.test(inputValue)) {
        // 手机号格式正确
        this.telephone = inputValue;
      } else {
        // 手机号格式不正确，不更新表单数据
        // 可以在界面上给出错误提示，或者其他处理方式
        console.log("手机号格式不正确");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.retrievePW {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .main {
    width: 500px;
    text-align: center;
    .title {
      font-size: 18px;
      height: 60px;
    }
    .content {
      width: 70%;
      margin: 30px auto 0;
      .el-form-item {
        text-align: left;
      }
      .nextBtn,
      .submitBtn {
        width: 100%;
      }
      //验证码按钮
      .codeBtn {
        margin-left: 5px;
      }
      //第三步
      .theThirdStep {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
      }
      //成功图标
      .icon {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tag {
        line-height: 60px;
      }
      .skipLoginBtn {
        color: #409eff;
      }
    }
  }
}
</style>
