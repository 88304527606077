<template>
  <ShopInfo :shopType='2' />
</template>

<script>
import ShopInfo from '@/views/shop/base/info/components/index'
export default {
  name: 'baseInfo',
  components: { ShopInfo }
}
</script>
